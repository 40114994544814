import React from 'react'

export const Icon = ({ color, size }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 60 60'
    width={size}
    height={size}
    id='test-icon'
  >
    <g>
      <path
        fill={color}
        d='M10,29.33h0a19.32,19.32,0,0,1,0-26.1,1.36,1.36,0,0,0,0-1.88L9.1.41A1.32,1.32,0,0,0,8.15,0h0A1.3,1.3,0,0,0,7.2.38s0,0,0,0a23.36,23.36,0,0,0,0,31.68,1.32,1.32,0,0,0,1,.42,1.36,1.36,0,0,0,.92-.36l0,0,.92-.94A1.34,1.34,0,0,0,10,29.33Z'
      />
      <path
        fill={color}
        d='M14,26.5a1.35,1.35,0,0,0,1,.43,1.31,1.31,0,0,0,.91-.35l.06,0,.92-.94a1.36,1.36,0,0,0,0-1.85,11.35,11.35,0,0,1,0-14.94A1.36,1.36,0,0,0,16.9,7l-.08-.1L15.91,6A1.29,1.29,0,0,0,15,5.6,1.32,1.32,0,0,0,14,6L14,6A15.38,15.38,0,0,0,14,26.5Z'
      />
      <path
        fill={color}
        d='M52.85.43h0A1.36,1.36,0,0,0,51.91,0h0A1.32,1.32,0,0,0,51,.36L50.9.4,50,1.34a1.35,1.35,0,0,0,0,1.87,19.33,19.33,0,0,1,0,26.11,1.36,1.36,0,0,0,0,1.88l.91.92a1.34,1.34,0,0,0,1.9,0l.05,0a23.39,23.39,0,0,0,0-31.68Z'
      />
      <path
        fill={color}
        d='M43.15,23.73h0a1.37,1.37,0,0,0,0,1.87l.91.93a1.34,1.34,0,0,0,1.9,0l.06-.07A15.38,15.38,0,0,0,46.05,6a1.36,1.36,0,0,0-.93-.44h-.07a1.32,1.32,0,0,0-.9.35L44.09,6,43.17,7a1.35,1.35,0,0,0,0,1.84A11.35,11.35,0,0,1,43.15,23.73Z'
      />
      <path
        fill={color}
        d='M34.64,22l.13-.11a7.51,7.51,0,1,0-9.55,0l.13.1L9.85,57.67a1.14,1.14,0,0,0,.59,1.51l1.67.72a1.14,1.14,0,0,0,1.51-.59l7.61-17.48H38.78l7.6,17.48a1.14,1.14,0,0,0,1.51.59l1.67-.72a1.14,1.14,0,0,0,.59-1.51ZM30,12.75a3.4,3.4,0,1,1-3.39,3.4A3.4,3.4,0,0,1,30,12.75Zm-7,25,6.15-14.13.16,0a5.16,5.16,0,0,0,1.36,0l.16,0L37,37.71Z'
      />
    </g>
  </svg>
)
