import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Icon } from '../images/icons'

import Layout from '../components/layout'
import SEO from '../components/seo'

import style from '../styles/style.module.scss'

const IndexPage = ({ data }) => {
  console.log(data.file.childImageSharp.fluid)

  return (
    <Layout>
      <SEO title='Home' />
      {/* <h1>Bloodhole</h1> */}
      <div className='cover-image'>
        <Img
          fluid={{ ...data.file.childImageSharp.fluid, aspectRatio: 3 / 1 }}
          style={{ borderRadius: '5px' }}
          alt='Hunters in a field'
        />
      </div>
      <div className={style.story}>
        <div className={style.intro}>
          <h2 className={style.title}>Our Story</h2>
          <p>
            Some info goes here as an introduction to Bloodhole... for life!
          </p>
        </div>
        <div className={style.row}>
          <div className={style.col}>
            <div className={style.iconVal}>
              <Icon color={`#333`} size={`40px`} />
              <h3>Value 1</h3>
              <p>We make hella-good quality stuff, you should really dig it.</p>
            </div>
          </div>
          <div className={style.col}>
            <div className={style.iconVal}>
              <Icon color={`#333`} size={`40px`} />
              <h3>Value 2</h3>
              <p>
                We're fun to be around, you should want to hang out some more.
              </p>
            </div>
          </div>
          <div className={style.col}>
            <div className={style.iconVal}>
              <Icon color={`#333`} size={`40px`} />
              <h3>Value 3</h3>
              <p>
                People are gonna love it, wear it with pride and make your
                friends jealous.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={style.products}>
        <div className={style.intro}>
          <h2 className={style.title}>Our Products</h2>
          <p>Lorem ipsum...</p>
        </div>
        <div className={style.productGrid}>
          <a href='#0' className={style.product}>
            <img
              src='//via.placeholder.com/400x400/e9ecef/343a40?text=Product+Here'
              alt='placholder image'
            />
            <span className={style.productTitle}>Product Title</span>
            <span className={style.productPrice}>Price</span>
          </a>
          <a href='#0' className={style.product}>
            <img
              src='//via.placeholder.com/400x400/e9ecef/343a40?text=Product+Here'
              alt='placholder image'
            />
            <span className={style.productTitle}>Product Title</span>
            <span className={style.productPrice}>Price</span>
          </a>
          <a href='#0' className={style.product}>
            <img
              src='//via.placeholder.com/400x400/e9ecef/343a40?text=Product+Here'
              alt='placholder image'
            />
            <span className={style.productTitle}>Product Title</span>
            <span className={style.productPrice}>Price</span>
          </a>
        </div>
        <div className={style.outro}>
          <Link className={style.cta} to={`/shop`}>
            View All&nbsp;<span>&rsaquo;</span>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    file(relativePath: { eq: "hunters-unsplash.jpg" }) {
      childImageSharp {
        id
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
